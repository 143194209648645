:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pt-t-space-4{padding-top:var(--t-space-4);}
:root ._pr-t-space-4{padding-right:var(--t-space-4);}
:root ._pb-t-space-4{padding-bottom:var(--t-space-4);}
:root ._pl-t-space-4{padding-left:var(--t-space-4);}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._gap-t-space-3{gap:var(--t-space-3);}
:root ._btlr-t-radius-2{border-top-left-radius:var(--t-radius-2);}
:root ._btrr-t-radius-2{border-top-right-radius:var(--t-radius-2);}
:root ._bbrr-t-radius-2{border-bottom-right-radius:var(--t-radius-2);}
:root ._bblr-t-radius-2{border-bottom-left-radius:var(--t-radius-2);}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pt-_gtXs_t-space-5{padding-top:var(--t-space-5);}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pr-_gtXs_t-space-5{padding-right:var(--t-space-5);}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pb-_gtXs_t-space-5{padding-bottom:var(--t-space-5);}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pl-_gtXs_t-space-5{padding-left:var(--t-space-5);}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._fb-_gtXs_0px{flex-basis:0px;}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._fg-_gtXs_1{flex-grow:1;}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._fs-_gtXs_1{flex-shrink:1;}}
:root ._btlr-t-radius-fu3456{border-top-left-radius:var(--t-radius-full);}
:root ._btrr-t-radius-fu3456{border-top-right-radius:var(--t-radius-full);}
:root ._bbrr-t-radius-fu3456{border-bottom-right-radius:var(--t-radius-full);}
:root ._bblr-t-radius-fu3456{border-bottom-left-radius:var(--t-radius-full);}
:root ._pt-t-space-2{padding-top:var(--t-space-2);}
:root ._pr-t-space-2{padding-right:var(--t-space-2);}
:root ._pb-t-space-2{padding-bottom:var(--t-space-2);}
:root ._pl-t-space-2{padding-left:var(--t-space-2);}
:root ._als-flex-start{align-self:flex-start;}
:root ._bg-interactive822765745{background-color:var(--interactive--neutral--restedInverted);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-onSurface--1130999482{color:var(--onSurface--neutral--default);}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-600{font-weight:600;}
:root ._ls-f-letterSpa779312659{letter-spacing:var(--f-letterSpacing-16);}
:root ._fos-f-size-20{font-size:var(--f-size-20);}
:root ._lh-f-lineHeigh3500603{line-height:var(--f-lineHeight-24);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._fow-400{font-weight:400;}
:root ._fos-f-size-16{font-size:var(--f-size-16);}
:root ._lh-f-lineHeigh3500601{line-height:var(--f-lineHeight-22);}