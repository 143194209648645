:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pr-_gtXs_t-space-4{padding-right:var(--t-space-4);}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pl-_gtXs_t-space-4{padding-left:var(--t-space-4);}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pt-_gtXs_t-space-4{padding-top:var(--t-space-4);}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pb-_gtXs_t-space-4{padding-bottom:var(--t-space-4);}}
:root ._w-10037{width:100%;}
:root ._mr-auto{margin-right:auto;}
:root ._ml-auto{margin-left:auto;}
:root ._maw-1440px{max-width:1440px;}
:root ._gap-t-space-4{gap:var(--t-space-4);}
:root ._pr-t-space-4{padding-right:var(--t-space-4);}
:root ._pl-t-space-4{padding-left:var(--t-space-4);}
:root ._pt-t-space-10{padding-top:var(--t-space-10);}
:root ._pb-t-space-10{padding-bottom:var(--t-space-10);}
:root ._gap-t-space-10{gap:var(--t-space-10);}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pr-_gtXs_t-space-12{padding-right:var(--t-space-12);}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pl-_gtXs_t-space-12{padding-left:var(--t-space-12);}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pt-_gtXs_t-space-20{padding-top:var(--t-space-20);}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pb-_gtXs_t-space-20{padding-bottom:var(--t-space-20);}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._gap-_gtXs_t-space-20{gap:var(--t-space-20);}}
@media (min-width: 1281px){:root:root:root:root:root:root:root:root:root:root:root ._pt-_gtMd_t-space-30{padding-top:var(--t-space-30);}}
@media (min-width: 1281px){:root:root:root:root:root:root:root:root:root:root:root ._pb-_gtMd_t-space-30{padding-bottom:var(--t-space-30);}}
@media (min-width: 1281px){:root:root:root:root:root:root:root:root:root:root:root ._gap-_gtMd_t-space-30{gap:var(--t-space-30);}}